const primaryFont = "'Inter', sans-serif";
const secondaryFont = "'Barlow', sans-serif";

export default {
  palette: {
    colors: {
      white: "#FFFFFF",
      black: "#000",
      pink: "#E31C79",
      bcgColor: "#D4F6F4",
      navyBlue: "#001871",
      grey100: "#D9D9D6",
    },
    fonts: {
      primaryFont,
      secondaryFont,
    },
    weight: {
      thin: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },

  overrides: {
    MuiButton: {
      root: {
        transition: "none",
        fontSize: "1.4rem",
        "&:focus": {
          backgroundColor: "#000",
          border: "1px solid #000",
        },
        "&:hover": {
          backgroundColor: "#000",
          border: "1px solid #000",
        },
      },
      text: {
        padding: 0,
      },
    },

    MuiInputLabel: {
      root: {
        fontFamily: primaryFont,
        fontWeight: 600,

        marginBottom: ".6rem",
        fontSize: "1.3rem",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1.3rem",
        fontFamily: primaryFont,
        "& input": {
          border: "1px solid #C5C5C5",
          backgroundColor: "#FFFFFF",
        },

        "&.Mui-error": {
          "& input": {
            border: "3px solid #EE4266",
          },
        },

        "&.Mui-focused": {
          "& input": {
            border: "3px solid #3C43CD",
          },
        },
      },

      input: {
        padding: "1rem",
        borderRadius: "6px",
        "&::placeholder": {
          fontSize: "1.3rem",
          fontFamily: primaryFont,
          color: "#C5C5C5",
        },
      },
    },
    MuiGrid: {
      container: {
        flex: "0 1 auto",
        width: "auto",
      },
    },

    MuiAutocomplete: {
      option: {
        color: "#323232",
      },
    },
    MuiSelect: {
      select: {
        padding: ".6rem 1rem",
        border: "1px solid #c5c5c5",
        backgroundColor: "FFFFFF",
        borderRadius: "5px",
        "&:focus": {
          borderRadius: "5px",
          backgroundColor: "#EAF0F8",
          border: "1px solid #c5c5c5",
        },
      },
    },
    MuiInput: {
      underline: {
        backgroundColor: "transparent",

        "&:hover:not($disabled):after": {
          backgroundColor: "transparent",
          display: "none",
        },
        "&:hover:not($disabled):before": {
          backgroundColor: "transparent",
          display: "none",
        },
        "&:after": {
          backgroundColor: "transparent",
          display: "none",
        },
        "&:before": {
          backgroundColor: "transparent",
          display: "none",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1.3rem",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "8px",
      },
      elevation8: {
        boxShadow: "none",
        border: "1px solid #C5C5C5",
        borderRadius: "5px",
      },
    },
  },
};
