import actionTypes from "store/types";

const { TOGGLE_MOBILE_MENU, CLOSE_MOBILE_MENU } = actionTypes;

const objInitialState = {
  isMobileMenuVisible: false,
};

const uiReducer = (state = objInitialState, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuVisible: !state.isMobileMenuVisible,
      };
    case CLOSE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuVisible: false,
      };

    default:
      return state;
  }
};

export default uiReducer;
