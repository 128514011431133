import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import GlobalStyles from "theme/globalStyles";
import Theme from "theme";

const MainTemplate = ({ children }) => (
  <>
    <GlobalStyles />
    <StyledThemeProvider theme={Theme.default}>{children}</StyledThemeProvider>
  </>
);

MainTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainTemplate;
