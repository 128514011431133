import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { device } from "styles/breakpoints";

const TitleOne = styled(({ ...rest }) => <Typography variant="h1" component="h1" {...rest} />)`
  &.h1 {
    font-family: ${({ theme: { palette } }) => palette.fonts.secondaryFont};
    color: ${({ theme: { palette } }) => palette.colors.black};
    font-weight: ${({ theme: { palette } }) => palette.weight.medium};
    font-size: 2rem;
    @media ${device.laptopL} {
      font-size: 2rem;
    }
  }
`;

const TitleTwo = styled(({ ...rest }) => <Typography variant="h2" component="h2" {...rest} />)`
  &.h2 {
    color: ${({ theme: { palette } }) => palette.colors.black};
    font-family: ${({ theme: { palette } }) => palette.fonts.primaryFont};
    font-weight: ${({ theme: { palette } }) => palette.weight.medium};
    font-size: 2.4rem;
    @media ${device.laptopL} {
      font-size: 3rem;
    }
  }
`;

const TitleThree = styled(({ ...rest }) => <Typography variant="h3" component="h3" {...rest} />)`
  &.h3 {
    color: ${({ theme: { palette } }) => palette.colors.black};
    font-family: ${({ theme: { palette } }) => palette.fonts.primaryFont};
    font-weight: ${({ theme: { palette } }) => palette.weight.medium};
    font-size: 2rem;
    @media ${device.laptopL} {
      font-size: 2.6rem;
    }
  }
`;

const TitleFour = styled(({ ...rest }) => <Typography variant="h4" component="h4" {...rest} />)`
  &.h4 {
    color: ${({ theme: { palette } }) => palette.colors.black};
    font-family: ${({ theme: { palette } }) => palette.fonts.primaryFont};
    font-weight: ${({ theme: { palette } }) => palette.weight.medium};
    font-size: 1.8rem;
    @media ${device.laptopL} {
      font-size: 2.4rem;
    }
  }
`;

const Paragraph = styled(({ ...rest }) => <Typography component="p" {...rest} />)`
  &.text {
    color: ${({ theme: { palette } }) => palette.colors.black};
    font-family: ${({ theme: { palette } }) => palette.fonts.primaryFont};
    font-weight: ${({ theme: { palette } }) => palette.weight.regular};
    font-size: 1.6rem;
  }
`;

export { TitleOne, TitleTwo, TitleThree, TitleFour, Paragraph };
