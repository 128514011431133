import React, { Component } from "react";
import PropTypes from "prop-types";
import { TitleTwo } from "styles/typography.styled";

// eslint-disable-next-line react/prefer-stateless-function
class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidMount() {
    window.addEventListener("error", this.handleAsyncErrors);
  }

  handleAsyncErrors = () => {
    this.setState({
      hasError: true,
    });
  };

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log("transmiting errors", error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // Alternatively add redirect to Error Page
      return (
        <div>
          <TitleTwo className="h1">Upss... Something went wrong.</TitleTwo>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
