import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import constants from "../constants";

const Home = lazy(() => import("containers/Home"));

const { ROOT } = constants.routes;

const routes = [{ isPrivate: false, exact: true, name: "Home Page", path: ROOT, component: Home }];

const AppRoutes = () => (
  <Switch>
    {routes.map(({ isPrivate, ...restProps }) => (
      <Route key={restProps.path} {...restProps} />
    ))}
  </Switch>
);

export { routes };

export default AppRoutes;
