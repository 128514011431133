import { createMuiTheme } from "@material-ui/core";
import defaultTheme from "./default";

const overrides = {
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    body1: {
      fontSize: "1.4rem",
    },
    caption: {
      fontSize: "1.3rem",
    },
    subtitle1: {
      fontSize: "1.3rem",
      fontWeight: 500,
      display: "block",
      textTransform: "uppercase",
    },

    subtitle2: {
      fontSize: "1.3rem",
      fontWeight: 600,
      display: "block",
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
  },
};

export default {
  default: createMuiTheme({
    ...defaultTheme,
    ...overrides,
  }),
};
