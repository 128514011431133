import React from "react";
import MainTemplate from "templates/MainTemplate";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "routes";
import ErrorBoundary from "components/common/ErrorBoundary";

const App = () => {
  return (
    <ErrorBoundary>
      <MainTemplate>
        <Router>
          <AppRoutes />
        </Router>
      </MainTemplate>
    </ErrorBoundary>
  );
};

export default App;
