import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import React, { Suspense } from "react";
import "react-app-polyfill/ie9";
import { createGenerateClassName, jssPreset, StylesProvider } from "@material-ui/core/styles";
import { create } from "jss";
import jssTemplate from "jss-plugin-template";
import { persistor, store } from "store";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";

window.__localeId__ = "en";

const jss = create({
  plugins: [jssTemplate(), ...jssPreset().plugins],
});

const generateClassName = createGenerateClassName({
  seed: "getMyHealthApp",
});

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />

      <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;500&display=swap" rel="stylesheet" />
    </Helmet>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StylesProvider jss={jss} generateClassName={generateClassName}>
            <App />
          </StylesProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </Suspense>,

  document.getElementById("root"),
);
